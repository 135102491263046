
  import { defineComponent, computed } from "vue"

  interface Props {
    cancelable: boolean,
  }

  export default defineComponent({
    name: 'CancelableDateTime',
    props: {
     cancelable: {
        type: Boolean,
        default: false
      }
    },
    setup(props: Props, { emit }) {
      const resetDate = () => {}
      return {
        resetDate
      }
    }
  })
